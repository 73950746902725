<script setup lang="ts">
import { cn } from "~/lib/utils";

const props = withDefaults( defineProps<{
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  class?: string;
}>(), {
  tag: "h2",
} );
</script>

<template>
  <component
    :is="tag"
    :class="cn('font-heading font-bold text-2xl sm:text-2xl md:text-3xl tracking-wide leading-relaxed text-balance', props.class)">
    <slot />
  </component>
</template>

<style scoped>

</style>
